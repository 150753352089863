import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { getRowClass, getRowStyle } from "constants/agGridCommonStyle";
import { VOL_SURFACE_STICKY_STRIKE_COLS } from "constants/volSurface";
import { GlobalContext } from "contexts/GlobalContext";
import { useVolSurfaceControlTable } from "contexts/VolSurfaceControlTableContext";
import { cellClickedListener, getRowId } from "helpers/generalHelper";
import useUpdateStrikeTable from "./hooks/useFetchStrikeTable";
import { onGridReady } from "helpers/volSurfaceHelper";
import { createPortal } from "react-dom";
import { Button, Modal } from "antd";

function SingleStrikeTable() {
  const [toNextCell, setToNextCell] = useState(false);
  const { setSelectedRows, stickyStrikeData } =
    useVolSurfaceControlTable();

  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);
  const gridRef = useRef();

  const {
    onCellEditingStopped,
    setShowUpdateModal,
    showUpdateModal,
    revertCellValue,
    isCellEditing,
  } = useUpdateStrikeTable({
    gridRef: gridRef,
    toNextCell: toNextCell,
  });
  const columnDefs = useMemo(
    () => VOL_SURFACE_STICKY_STRIKE_COLS(isCellEditing),
    [isCellEditing]
  );

  const gridOptions = {
    stopEditingWhenGridLosesFocus: false,
    animateRows: true,
    // isRowSelectable: function (rowNode) {
    //   return !rowNode.data.show_tenor_and_ul && selectedRows.length === 0;
    // },
  };

  const refreshTable = () => {
    let refreshParams = { force: true };
    gridRef.current.api.refreshCells(refreshParams);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        setToNextCell(true);
      } else if (event.key === "Escape") {
        setToNextCell(false);
      }
    };

    const handleMouseDown = () => {
      setToNextCell(false);
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleMouseDown);

    // Cleanup function to remove event listeners
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  const onSelectionChanged = (e) => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    setSelectedRows(
      selectedRows.filter((item) => item.show_tenor_and_ul === false)
    );
    refreshTable();
  };

  const onCancel = () => {
    setShowUpdateModal({ data: null, show: false });
    revertCellValue(showUpdateModal.data);
  };

  if (!stickyStrikeData?.table) return null;
  
  return (
    <>
      <AgGridReact
        getRowId={getRowId}
        onCellEditingStopped={(event) => {
          if (
            event.colDef.field === "underlying" &&
            event.newValue !== null &&
            event.newValue !== +event.oldValue
          ) {
            setShowUpdateModal({
              show: true,
              data: event,
            });
          }else if (event.colDef.field === "underlying") {
            revertCellValue(event);
          } else {
            onCellEditingStopped(event);
          }
        }}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        headerHeight={30}
        defaultColDef={{ suppressMenu: true }}
        rowHeight={30}
        rowData={stickyStrikeData?.table}
        onSelectionChanged={onSelectionChanged}
        getRowStyle={(params) => {
          if (params.data.field !== "strike") {
            return {
              borderTop: "none",
              borderBottom: "none",
            };
          }
          getRowStyle(params);
        }}
        getRowClass={getRowClass}
        ref={gridRef}
        onCellValueChanged={(event) => {
          if (event.source === "paste") {
            onCellEditingStopped(event);
          }
        }}
        
        stopEditingWhenCellsLoseFocus={true}
        suppressMovableColumns={true}
        columnDefs={columnDefs}
        groupDefaultExpanded={1}
        suppressContextMenu
        checkboxSelection
        rowSelection={"multiple"}
        onCellClicked={(e) => {
          if (e.colDef.field === "actions") return;
          cellClickedListener(e);
          refreshTable();
        }}
        suppressRowClickSelection
        className={`ag-theme-alpine pricer-ag-grid__table ${
          dark_Theme ? " ag-theme-balham-dark" : "ag-theme-balham"
        }`}
      />
      {createPortal(
        <Modal
          className={`${dark_Theme && "ant-modal-dark content_dark"}`}
          title="Would you like to keep or reset the strikes?"
          style={{
            top: 20,
          }}
          confirmLoading={false}
          open={showUpdateModal.show}
          footer={[
            <Button type="ghost" onClick={onCancel}>
              Cancel
            </Button>,
          ]}
          onCancel={onCancel}
          centered
        >
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex justify-content-center gap-3 w-50 align-items-center">
              <Button
                className="w-50"
                type="primary"
                onClick={() => {
                  setShowUpdateModal({ show: false });
                  onCellEditingStopped(showUpdateModal.data, 1);
                }}
              >
                Keep
              </Button>
              <Button
                className="w-50"
                type="default"
                onClick={() => {
                  setShowUpdateModal({ show: false });
                  onCellEditingStopped(showUpdateModal.data, 0);
                }}
              >
                Reset
              </Button>
            </div>
          </div>
        </Modal>,
        document.body
      )}
    </>
  );
}

export default SingleStrikeTable;
