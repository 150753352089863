import { Button, InputNumber } from "antd";
import HedgeItem from "./HedgeItem";
import CustomTradeInputForDelta from "components/Common/CustomTradeInputForDelta/CustomTradeInputForDelta";
import { API_KEYS, BACKEND_KEYS_NAME } from "constants/editTradeDetails";
import TraderDropdown from "./TraderDropdown";
import CompanyTraders from "./CompanysTrader";
import { Fragment, useCallback } from "react";
import LegQuantityItem from "./LegQuantityItem";
import { debounce } from "lodash";
import { updateQuantityTrade } from "api/editTradeDetailsApi";
import AddColButton from "./AddColButton";
import RemoveColButton from "./RemoveColButton";

function TradeColItem({
  col,
  tradeData,
  colIndex,
  tradingCompanyId,
  setTradingCompanyId,
  handleTraderChange,
  editQuantityValue,
  isTradeRequestFire,
  setIsRequestActive,
  handleLegsQuantityChange,
  setTradeData,
  isSellActive,
  isRequestActive,
  oldTradeData,
  setOldTradeData,
  setIsQuantityRequestActive,
  setIsDeltaRequestActive,
  isRequestsActive,
  props,
}) {
  const handleHedgeQuantityChange = (
    hedge_col,
    value,
    col_index,
    updateValue = true
  ) => {
    setTradeData((prev) => {
      return {
        ...prev,
        data: {
          ...prev.data,
          trades: prev.data.trades.map((col) => {
            col.hedges = col.hedges.map((hedge) => {
              if (
                hedge.hedge_col !== hedge_col ||
                hedge.col_index !== col_index
              )
                return hedge;
              if (updateValue) {
                setOldTradeData((old) => ({
                  ...old,
                  delta_quantity_t: hedge.delta_quantity_t?.value,
                  delta: hedge.delta?.value,
                }));
              }
              return {
                ...hedge,
                delta_quantity_t: {
                  ...hedge.delta_quantity_t,
                  value: value?.delta_quantity,
                },
                delta: {
                  ...hedge.delta,
                  value: value.delta,
                },
              };
            });
            return col;
          }),
        },
      };
    });
  };

  function handleQuanityChange(value, id, updateValue = true) {
    setTradeData((prev) => {
      return {
        ...prev,
        data: {
          ...prev.data,
          trades: prev.data.trades.map((item) => {
            if (item.column_id === id) {
              if (updateValue) {
                setOldTradeData((old) => ({
                  ...old,
                  quantity_t: item.quantity_t.value,
                }));
              }
              item.quantity_t = {
                ...item.quantity_t,
                value,
              };
            }
            return item;
          }),
        },
      };
    });
  }
  const handleCompanyTraderChange = (id, value, trader_company_id) => {
    const trades = tradeData.data.trades.map((item) => {
      if (item.column_id === id) {
        item.trading_company = value;
        item["trading_company_id"] = trader_company_id;
      }
      return item;
    });
    setTradeData((prev) => {
      return {
        ...prev,
        data: {
          ...prev.data,
          trades: trades,
        },
      };
    });
  };

  const handleHedgeSwapSignBtnChange = () => {
    setTradeData((prev) => {
      return {
        ...prev,
        data: {
          ...prev.data,
          trades: prev.data.trades.map((item) => {
            return {
              ...item,
              hedges: item.hedges.map((hedge) => {
                return {
                  ...hedge,
                  sell_or_buy: hedge.sell_or_buy === "buy" ? "sell" : "buy",
                };
              }),
            };
          }),
          left: {
            exchange_placeholder: prev.data.left?.exchange_placeholder,
            exchanges: prev.data.left?.exchanges,
            is_sell: prev.data.left?.is_sell,
            legs: prev.data.left?.legs,
            title: prev.data.left?.title,
            hedges: prev.data.left?.hedges.map((item) => {
              return {
                ...item,
                sell_or_buy: item.sell_or_buy === "buy" ? "sell" : "buy",
              };
            }),
          },
        },
      };
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedQuanityChange = useCallback(
    debounce((value, id, hedge_col, leg_col) => {
      const prepareBody = {
        [BACKEND_KEYS_NAME.IS_SELL]: isSellActive ? 1 : 0,
        quantity: value,
        ...((props.isTeamRun || props.isPricer) && {
          temp_summary_id: tradeData?.data?.temp_summary_id,
        }),
      };

      setIsRequestActive(true);
      setIsQuantityRequestActive(true);
      updateQuantityTrade(prepareBody)
        .then((res) => {
          setTradeData((prev) => {
            console.log("res > ", res);
            console.log("cols > ", prev, id);
            return {
              ...prev,
              data: {
                ...prev.data,
                trades: prev.data.trades.map((col) => {
                  if (col.column_id !== id) return col;
                  col.legs = res.data.data.legs;
                  col.hedges = col.hedges.map((hedge) => {
                    if (hedge.leg_col !== leg_col) return hedge;
                    return {
                      ...hedge,
                      delta_quantity_t: {
                        ...hedge.delta_quantity_t,
                        value: res.data.data.hedges?.find(
                          (resHedge) => hedge.id === resHedge.id
                        )?.delta_quantity_t?.value,
                      },
                      delta: {
                        ...hedge.delta,
                        value: res.data.data.hedges?.find(
                          (resHedge) => hedge.id === resHedge.id
                        )?.delta?.value,
                      },
                    };
                  });

                  return col;
                }),
              },
            };
          });
        })
        .catch((error) => {
          handleQuanityChange(oldTradeData.quantity_t, id, false);
          console.log("error > ", error);
        })
        .finally(() => {
          setIsRequestActive(false);
          setIsQuantityRequestActive(false);
        });
    }, 1000),
    [tradeData?.data?.temp_summary_id]
  );

  return (
    <div>
      <div
        className="d-flex"
        style={{
          marginBottom: "15px",
          marginRight: tradeData.data.trades.length - 1 === colIndex ? "0" : 20,
          width: tradeData.data.trades.length - 1 === colIndex ? 320 : 260,
        }}
      >
        <div style={{ width: 200 }}>
          <CompanyTraders
            id={col.column_id}
            isAllowClear={false}
            isDisabled={
              (isTradeRequestFire === null || isRequestsActive || colIndex) !==
              0
            }
            onChange={handleCompanyTraderChange}
            setTradingCompanyId={setTradingCompanyId}
            defaultValue={tradeData?.data?.trades?.at(0).trading_company}
            placeholder={col?.[API_KEYS.COMPANY_TRADE_PLACEHOLDER]}
            value={tradeData?.data?.trades?.at(0).trading_company}
            minWidth={"100%"}
          />
        </div>
        {tradeData.data.trades.length === 1 && colIndex === 0 && (
          <AddColButton
            isTradeRequestFire={isTradeRequestFire}
            isRequestsActive={isRequestsActive || isRequestActive}
            setTradeData={setTradeData}
          />
        )}
        {tradeData.data.trades.length > 1 && (
          <div className="d-flex">
            {tradeData.data.trades.length - 1 === colIndex && (
              <AddColButton
                isTradeRequestFire={isTradeRequestFire}
                isRequestsActive={isRequestsActive || isRequestActive}
                setTradeData={setTradeData}
              />
            )}
            <RemoveColButton
              col={col}
              setTradeData={setTradeData}
              isTradeRequestFire={isTradeRequestFire}
              isRequestsActive={isRequestsActive || isRequestActive}
            />
          </div>
        )}
      </div>
      <div style={{ marginBottom: "15px", width: 200 }}>
        <TraderDropdown
          isDisabled={isTradeRequestFire === null || isRequestsActive}
          id={col.column_id}
          tradingCompanyId={tradingCompanyId}
          apiData={col}
          minWidth={"100%"}
          onChange={handleTraderChange}
          setTraderId={() => {}}
          item={col}
          isAllowClear={false}
        />
      </div>
      <div style={{ width: 200 }}>
        <InputNumber
          onStep={() => setIsRequestActive(true)}
          onInput={() => setIsRequestActive(true)}
          onFocus={() => setIsRequestActive(true)}
          onBlur={() => setIsRequestActive(false)}
          type="number"
          placeholder={col?.[API_KEYS.QUANTIZE_PLACEHOLDER]}
          disabled={
            isTradeRequestFire === null ||
            isRequestsActive ||
            col[BACKEND_KEYS_NAME.QUANTITY_T]?.[
              BACKEND_KEYS_NAME.IS_DISABLED
            ] ||
            false
          }
          value={
            editQuantityValue !== undefined
              ? editQuantityValue
              : col[BACKEND_KEYS_NAME.QUANTITY_T]?.[BACKEND_KEYS_NAME.VALUE]
          }
          step={
            col[BACKEND_KEYS_NAME.QUANTITY_T][BACKEND_KEYS_NAME.STEP_SIZE] ||
            0.005
          }
          onChange={(value) => {
            handleQuanityChange(value, col.column_id);
            debouncedQuanityChange(value, col.column_id);
          }}
          addonAfter={
            col[BACKEND_KEYS_NAME.QUANTITY_T]?.[BACKEND_KEYS_NAME.SYMBOL]
          }
        />
      </div>
      {col.legs.map((leg, legIndex) => (
        <LegQuantityItem
          key={leg.id ?? legIndex}
          handleLegsQuantityChange={handleLegsQuantityChange}
          leg={leg}
          isTradeRequestFire={isTradeRequestFire}
          setIsRequestActive={setIsRequestActive || isRequestsActive}
        />
      ))}
      {col.hedges?.map((hedge, hedgeIndex) => {
        return (
          <Fragment key={hedge.id ?? hedgeIndex}>
            <div className="mb-3 d-flex">
              <div style={{ width: 200 }}>
                <CustomTradeInputForDelta
                  isDelta={false}
                  trades={tradeData?.data?.trades}
                  colId={col.column_id}
                  isDisabled={isTradeRequestFire === null || isRequestsActive}
                  hedge={hedge}
                  placeholdersTitle={col}
                  handleHedgeQuantityChange={handleHedgeQuantityChange}
                  temp_summary_id={tradeData.data?.temp_summary_id}
                  setIsRequestActive={setIsRequestActive}
                  isSellActive={isSellActive}
                  setIsDeltaRequestActive={setIsDeltaRequestActive}
                  oldTradeData={oldTradeData}
                />
              </div>
              {tradeData.data.trades.length - 1 === colIndex &&
                hedgeIndex === 0 && (
                  <Button
                    disabled={
                      isTradeRequestFire === null ||
                      isRequestActive ||
                      isRequestsActive
                    }
                    className="mx-3"
                    type="primary"
                    onClick={() => {
                      handleHedgeSwapSignBtnChange();
                    }}
                  >
                    Swap sign
                  </Button>
                )}
            </div>
            <div className="mb-3" style={{ width: 200 }}>
              <HedgeItem
                trades={tradeData?.data?.trades}
                colId={col.column_id}
                isDisabled={isTradeRequestFire === null || isRequestsActive}
                hedge={hedge}
                temp_summary_id={tradeData.data.temp_summary_id}
                placeholdersTitle={col}
                isSellActive={isSellActive}
                setIsRequestActive={setIsRequestActive}
                handleHedgeQuantityChange={handleHedgeQuantityChange}
                setIsDeltaRequestActive={setIsDeltaRequestActive}
                oldTradeData={oldTradeData}
              />
            </div>
          </Fragment>
        );
      })}
    </div>
  );
}

export default TradeColItem;
